import B2bRequestsTile from './b2b-requests-tile';
import B2bRequestsTileView from './b2b-requests-tile.view';

export * from './b2b-requests-tile';
export * from './b2b-requests-tile.view';
export * from './b2b-requests-tile.data';
export { IB2bRequestsTileConfig, IB2bRequestsTileProps } from './b2b-requests-tile.props.autogenerated';

export {
    B2bRequestsTile,
    B2bRequestsTileView
};