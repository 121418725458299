import SignUp from './sign-up';
import SignUpView from './sign-up.view';

export * from './sign-up';
export * from './sign-up.view';
export * from './components';
export * from './sign-up.props.autogenerated';

export {
    SignUp,
    SignUpView
};