import AccountOrderTemplatesTile from './account-order-templates-tile';
import AccountOrderTemplatesTileView from './account-order-templates-tile.view';

export * from './account-order-templates-tile';
export * from './account-order-templates-tile.view';
export * from './account-order-templates-tile.data';
export { IAccountOrderTemplatesTileConfig, IAccountOrderTemplatesTileResources, IAccountOrderTemplatesTileProps } from './account-order-templates-tile.props.autogenerated';

export {
    AccountOrderTemplatesTile,
    AccountOrderTemplatesTileView
};