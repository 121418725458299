import B2bRequestsStatus from './b2b-requests-status';
import B2bRequestsStatusView from './b2b-requests-status.view';

export * from './b2b-requests-status';
export * from './b2b-requests-status.view';
export * from './b2b-requests-status.data';
export { IB2bRequestsStatusConfig, IB2bRequestsStatusProps } from './b2b-requests-status.props.autogenerated';

export {
    B2bRequestsStatus,
    B2bRequestsStatusView
};