import AccountAddressTile from './account-address-tile';
import AccountAddressTileView from './account-address-tile.view';

export * from './account-address-tile';
export * from './account-address-tile.view';
export * from './account-address-tile.data';
export * from './components';
export { IAccountAddressTileConfig, IAccountAddressTileResources , ILinksData, IAccountAddressTileProps } from './account-address-tile.props.autogenerated';

export {
    AccountAddressTile,
    AccountAddressTileView
};