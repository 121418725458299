import AccountWelcomeTile from './account-welcome-tile';
import AccountWelcomeTileView from './account-welcome-tile.view';

export * from './account-welcome-tile';
export * from './account-welcome-tile.view';
export * from './account-welcome-tile.data';
export { IAccountWelcomeTileConfig, IAccountWelcomeTileProps } from './account-welcome-tile.props.autogenerated';

export {
    AccountWelcomeTile,
    AccountWelcomeTileView
};