import LoyaltySignUp from './loyalty-sign-up';
import LoyaltySignUpView from './loyalty-sign-up.view';

export * from './loyalty-sign-up';
export * from './loyalty-sign-up.view';
export * from './loyalty-sign-up.data';
export { ILoyaltySignUpConfig, ILoyaltySignUpResources, MemberHeadingTag, IMemberHeadingData, ILoyaltySignUpProps } from './loyalty-sign-up.props.autogenerated';

export {
    LoyaltySignUp,
    LoyaltySignUpView
};
