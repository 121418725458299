import AccountLoyalty from './account-loyalty';
import AccountLoyaltyView from './account-loyalty.view';

export * from './account-loyalty';
export * from './account-loyalty.view';
export * from './account-loyalty.data';
export * from './components';
export { IAccountLoyaltyConfig, IAccountLoyaltyResources, IAccountLoyaltyProps } from './account-loyalty.props.autogenerated';

export {
    AccountLoyalty,
    AccountLoyaltyView
};