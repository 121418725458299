import AccountProfile from './account-profile';
import AccountProfileView from './account-profile.view';

export * from './account-profile';
export * from './account-profile.view';
export * from './account-profile.data';
export * from './components';
export {
    IAccountProfileConfig,
    IAccountProfileResources,
    NameSectionHeadingTag,
    INameSectionHeadingData,
    EmailAddressSectionHeadingTag,
    IEmailAddressSectionHeadingData,
    PreferencesSectionHeadingTag,
    IPreferencesSectionHeadingData,
    PersonalizationSectionHeadingTag,
    IPersonalizationSectionHeadingData,
    IAccountProfileProps
} from './account-profile.props.autogenerated';

export {
    AccountProfile,
    AccountProfileView
};