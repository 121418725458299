import AccountWishlistTile from './account-wishlist-tile';
import AccountWishlistTileView from './account-wishlist-tile.view';

export * from './account-wishlist-tile';
export * from './account-wishlist-tile.view';
export * from './account-wishlist-tile.data';
export { IAccountWishlistTileConfig, IAccountWishlistTileResources, IAccountWishlistTileProps } from './account-wishlist-tile.props.autogenerated';

export {
    AccountWishlistTile,
    AccountWishlistTileView
};