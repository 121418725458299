import AccountLoyaltyTile from './account-loyalty-tile';
import AccountLoyaltyTileView from './account-loyalty-tile.view';

export * from './account-loyalty-tile';
export * from './account-loyalty-tile.view';
export * from './account-loyalty-tile.data';
export * from './components';
export { IAccountLoyaltyTileConfig, IAccountLoyaltyTileResources, IAccountLoyaltyTileProps } from './account-loyalty-tile.props.autogenerated';

export {
    AccountLoyaltyTile,
    AccountLoyaltyTileView
};