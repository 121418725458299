import LoyaltyTerms from './loyalty-terms';
import LoyaltyTermsView from './loyalty-terms.view';

export * from './loyalty-terms';
export * from './loyalty-terms.view';
export * from './loyalty-terms.data';
export * from './components';
export { ILoyaltyTermsConfig, ILoyaltyTermsResources, ILoyaltyTermsProps } from './loyalty-terms.props.autogenerated';

export {
    LoyaltyTerms,
    LoyaltyTermsView
};